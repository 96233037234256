<template>
    <div id="nav-header">
        <div id="header">
            <a href="#" class="logo">silajdzicsead.com</a>
            <nav>
                <RouterLink :to="{name: 'home', hash: '#hero'}">Home</RouterLink>
                <RouterLink :to="{name: 'home', hash: '#services'}">Services</RouterLink>
                <RouterLink :to="{name: 'home', hash: '#projects'}">Work</RouterLink>
                <RouterLink :to="{name: 'home', hash: '#contact'}">Contact</RouterLink>
                <RouterLink :to="{name: 'about'}">Learn more about me</RouterLink>
            </nav>
            <button type="submit" onclick="window.open('./SeadSilajdzic-cv.pdf')" class="cta-light">Download CV</button>
        </div>
    </div>
</template>

<style scoped lang="scss">
@import "/src/assets/styles/app.scss";

#nav-header {
    border-bottom: 1px solid $border;
    height: fit-content;

    #header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 3rem;

        .logo {
            margin-left: 2rem;
            font-size: .9rem;
            font-weight: 600;
            text-decoration: none;
            color: $dark;
        }

        nav {
            height: 100%;
            display: flex;

            a {
                display: flex;
                align-items: center;
                color: $dark;
                text-decoration: none;
                transition: all 0.4s;
                font-size: .9rem;
                font-weight: 500;
                height: 100%;
                padding: 0 2rem;

                &:hover {
                    background: $dark;
                    color: $light;
                }
            }
        }

        .cta-light {
            color: $light;
            padding: 1rem 2rem;
            text-decoration: none;
            border: $dark;
            text-transform: capitalize;
            font-size: .9rem;
            font-weight: 500;
            cursor: pointer;
            background: $dark;
            height: 100%;
        }
    }

    @media screen and (max-width: $mobileMedia) {
        height: fit-content;

        #header {
            flex-direction: column;
            gap: 1.2rem;
            height: fit-content;

            .logo {
                margin-left: unset;
                font-size: .8rem;
                margin-top: 1rem;
            }

            nav {
                height: 100%;
                width: 100%;
                justify-content: space-around;
                flex-wrap: wrap;
                gap: .5rem;

                a {
                    display: inline-block;
                    transition: unset;
                    height: unset;
                    padding: unset;

                    &:hover {
                        background: unset;
                        color: unset;
                    }
                }
            }

            .cta-light {
                padding: .5rem 1.5rem;
                font-size: .8rem;
            }
        }
    }
}
</style>
<script setup lang="ts">
</script>