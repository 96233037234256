<script setup>

</script>

<template>
    <section id="hero" class="hero">
        <div class="headlines">
            <h1>Do you have a website idea?</h1>
            <h2>Ready to [strategize, design, build, customize, debug, optimize, deploy, secure] your next big
                web project? Let's talk about bringing it to life</h2>
            <RouterLink :to="{name: 'home', hash: '#contact'}">Contact me</RouterLink>
        </div>
        <img src="../assets/code-image.webp" alt="Code image">
    </section>
</template>

<style scoped lang="scss">
@import "/src/assets/styles/app.scss";

.hero {
    display: flex;
    justify-content: space-between;
    margin: 3rem 0;

    .headlines {
        display: flex;
        flex-direction: column;
        justify-content: center;

        h1 {
            font-size: 4rem;
            line-height: 4rem;
            margin: 0;
            font-weight: 700;
            color: $dark;
        }

        h2 {
            font-size: 1.4rem;
            font-weight: 400;
            line-height: 2rem;
            color: $dark;
            margin: 3rem 0;
        }
    }

    img {
        width: 50%;
    }

    a {
        font-size: 1rem;
        text-decoration: none;
        color: $dark;
        border: none;
        background: $accent;
        border-radius: .5rem;
        padding: 1rem 2rem;
        width: fit-content;
        transition: all 0.4s;
        position: relative;

        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            height: 0;
            width: 100%;
            border-radius: 5px;
            transition: all 0.4s;
        }

        &:hover {
            color: $light;
            z-index: 1;
            box-shadow: 0 2px 10px $dark;

            &:after {
                height: 100%;
                background: $dark;
                z-index: -1;
            }
        }
    }

    @media screen and (max-width: $mobileMedia) {
        flex-direction: column;
        margin: 5rem 0 2rem 0;
        gap: 2rem;
        padding: 1rem;

        .headlines {
            h1 {
                font-size: 3rem;
                line-height: 3rem;
            }

            h2 {
                font-size: 1rem;
                line-height: 1.5rem;
                margin: 2rem 0;
            }
        }

        img {
            width: 100%;
        }
    }
}
</style>