<script setup>
import {ref} from "vue";
import axios from 'axios';

const name = ref();
const email = ref();
const message = ref();
const errors = ref();
const responseMessage = ref();

const contact = async () => {
    const response = await axios.post('https://backend.silajdzicsead.com/api/contact', {
        name: name.value,
        email: email.value,
        message: message.value,
    })
        .then((response) => {
            responseMessage.value = response.data.message;
            name.value = '';
            email.value = '';
            message.value = '';
            errors.value = {};
            setTimeout(() => {
                document.querySelector('.responseMessage').classList.remove('active');
            },2000)
        })
        .catch(error => errors.value = error.response.data.error);
}
</script>

<template>
    <section id="contact">
        <h2>Get in touch</h2>
        <p>Interested in working together? Contact me to check the offer...</p>
        <form @submit.prevent="contact">
            <div>
                <input v-model="name" :class="{'error-border': errors && errors.name}" type="text" name="name" placeholder="Name" required>
                <p v-if="errors && errors.name" class="error-message">{{ errors.name[0] }}</p>
            </div>

            <div>
                <input v-model="email" :class="{'error-border': errors && errors.email}" type="email" name="email" placeholder="Email" required>
                <p v-if="errors && errors.email" class="error-message">{{ errors.email[0] }}</p>
            </div>

            <div>
                <textarea v-model="message" :class="{'error-border': errors && errors.message}" name="message" id="message" cols="30" rows="10" placeholder="Message"></textarea>
                <p v-if="errors && errors.message" class="error-message">{{ errors.message[0] }}</p>
            </div>

            <button type="submit" class="cta">Send message</button>
        </form>
        <p v-if="responseMessage" :class="{'active': responseMessage}" class="responseMessage">{{ responseMessage }}</p>
    </section>
</template>

<style scoped lang="scss">
@import "/src/assets/styles/app.scss";

#contact {
    margin: 0 0 15rem 0;
    text-align: center;

    h2 {
        font-size: 4rem;
        margin: 0;
    }

    p {
        font-size: 1.2rem;
        font-weight: 400;
        line-height: 2rem;
        color: $dark;
        margin: 1rem 0 3rem 0;
    }

    form {
        display: flex;
        flex-direction: column;
        width: 50%;
        gap: 1.2rem;
        margin: 0 auto;

        input,
        textarea {
            width: 100%;
            box-sizing: border-box;
            border: none;
            font-size: 1rem;
            padding: .7rem;
            border-radius: 5px;
            background: $light;
            color: $dark;
            box-shadow: 1px 5px 10px lighten($dark, 80%);
        }

        textarea {
            resize: vertical;
        }

        button {
            border: none;
            border-radius: 5px;
            width: fit-content;
            padding: 1rem 2.5rem;
            margin: 0 0 0 auto;
            background: $dark;
            color: $light;
            font-size: 1rem;
            cursor: pointer;
            transition: all .2s;

            &:hover {
                transform: translateY(-3px);
                box-shadow: 0 3px 1px lighten($dark, 80%);
            }

            &:active {
                transform: translateY(0px);
                box-shadow: unset;
                transition-duration: 0s;
            }
        }
    }

    @media screen and (max-width: 600px) {
        margin: 3rem 0 5rem 0;
        padding: 1rem;

        h2 {
            font-size: 2rem;
        }

        p {
            font-size: 1rem;
            line-height: 1.5rem;
        }

        form {
            width: 100%;

            input,
            textarea {
                width: 100%;
                box-sizing: border-box;
                border: none;
                font-size: 1rem;
                padding: .7rem;
                border-radius: 5px;
                background: $light;
                color: $dark;
                box-shadow: 1px 5px 10px lighten($dark, 80%);
            }

            button {
                &:hover {
                    transform: unset;
                    box-shadow: unset;
                }

                &:active {
                    transform: unset;
                    transition-duration: unset;
                }
            }
        }
    }

    .responseMessage {
        opacity: 0;
        pointer-events: none;
        transition: opacity .4s;

        &.active{
            opacity: 1;
            transition: opacity .4s;
        }
    }

    .error-border {
        border: 1px solid #9a2d2d !important;
        border-radius: 10px !important;
    }

    .error-message {
        margin: 0;
        padding: 0;
        text-align: start;
        font-size: .8rem;
        color: #9a2d2d;
        line-height: 1.2rem;
    }
}
</style>