<script setup>
const projects = [
    {
        id: 1,
        name: 'Movies management',
        description: 'This project demonstrates the development of a movie-related application using the Laravel framework. It includes features such as movie listings and detailed information, showcasing skills in PHP, Blade templating, and basic JavaScript integration.',
        url: 'https://github.com/SeadSilajdzic/seadSilajdzic-movies',
        tech: ['PHP', 'Blade', 'JavaScript']
    },
    {
        id: 2,
        name: 'Car rental application',
        description: 'An application featuring API endpoints tailored for a conceptual car rental company.',
        url: 'https://github.com/SeadSilajdzic/car-rental-apli',
        tech: ['JavaScript', 'PHP', 'Blade']
    },
    {
        id: 3,
        name: 'Vue blog',
        description: 'A blog application developed using Vue.js. It demonstrates modern front-end development practices, providing dynamic content management and a responsive user experience.',
        url: 'https://github.com/SeadSilajdzic/blog-vue',
        tech: ['Vue', 'JavaScript', 'HTML']
    },
    {
        id: 4,
        name: 'Planner',
        description: 'A project management application built with Vue.js. It enables users to efficiently plan and organize tasks, providing a user-friendly interface for managing projects.',
        url: 'https://github.com/SeadSilajdzic/project-planner-vue',
        tech: ['Vue', 'JavaScript', 'HTML']
    },
    {
        id: 5,
        name: 'Wordpress plugin - Slugbuddy',
        description: 'A plugin designed to resolve issues where media predates posts but shares identical slugs.',
        url: 'https://github.com/SeadSilajdzic/slugbuddy-wp-plugin',
        tech: ['PHP']
    },
    {
        id: 6,
        name: 'Wordpress plugin - Data transfer',
        description: 'A WordPress plugin ensuring structured data is accurately populated into the site. This serves as an extension to a previously developed private plugin, offering valuable insights and inspiration.',
        url: 'https://github.com/SeadSilajdzic/data-transfer-wp-plugin',
        tech: ['PHP']
    },
    {
        id: 7,
        name: 'URL Shortener',
        description: 'A Laravel-based project that provides a URL shortening service. This application allows users to generate shortened URLs for easier sharing and management of links, ensuring efficient and user-friendly handling of lengthy URLs.',
        url: 'https://github.com/SeadSilajdzic/url-shortener',
        tech: ['PHP', 'Vue', 'Blade', 'JavaScript']
    },
    {
        id: 8,
        name: 'Automated file backup',
        description: 'A Python solution for automating file backups. This project ensures data integrity and security through scheduled and automated backup processes, minimizing the risk of data loss.',
        url: 'https://github.com/SeadSilajdzic/automated-file-backup',
        tech: ['Python']
    },
    {
        id: 9,
        name: 'Youtube video downloader',
        description: 'A Python-based tool for downloading YouTube videos. It offers a straightforward way to save YouTube content locally for offline access and personal use.',
        url: 'https://github.com/SeadSilajdzic/py-yt-video-downloader',
        tech: ['Python']
    },
    {
        id: 10,
        name: 'VueLog',
        description: 'An informative project focused on learning Vue.js by developing a blog with Laravel, Vue, and Inertia.',
        url: 'https://github.com/SeadSilajdzic/VueLOG',
        tech: ['PHP', 'Vue', 'Blade']
    },
    {
        id: 11,
        name: 'Currency converter',
        description: 'A Python application designed to convert currencies using real-time exchange rates. This tool simplifies the process of currency conversion, making it quick and accurate.',
        url: 'https://github.com/SeadSilajdzic/py-currency-converter',
        tech: ['Python']
    },
    {
        id: 12,
        name: 'DineIn',
        description: 'Dine in is a product of my own which is used for restaurant management or any other facility of that use.',
        url: 'https://caffebarbest.silajdzicsead.com/en',
        tech: ['CSS', 'PHP', 'Blade', 'SCSS', 'JavaScript', 'HTML', 'Shell']
    }
];
</script>

<template>
    <section id="projects">
        <div class="header">
            <h2>Selected work</h2>
        </div>
        <div v-for="project in projects" :key="project.id" class="project">
            <div class="description">
                <h3>{{ project.name }}</h3>
                <p>{{ project.description }}</p>
                <a :href="project.url" target="_blank">View details &gt;</a>
            </div>
            <div class="visual">
                <img src="@/assets/project.webp" alt="Project preview image">
            </div>
        </div>
    </section>
</template>

<style scoped lang="scss">
@import "/src/assets/styles/app.scss";

#projects {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    justify-content: space-between;
    position: relative;
    margin: 4rem 0;

    .header {
        width: 100%;
        margin: 4rem 0 2rem 0;

        h2 {
            color: $dark;
            font-size: 2rem;
            margin: 0;
            text-align: left;
        }
    }

    & .project {
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: fit-content;

        h3 {
            font-size: 1.5rem;
            margin-bottom: 0;
            color: $dark;
        }

        p {
            font-size: .9rem;
            color: $dark;
        }

        a {
            text-decoration: none;
            color: darken($accent, 35%);
            font-size: .9rem;
            text-transform: capitalize;
            margin-top: 1rem;
            display: inline-block;
        }

        .visual {
            img {
                width: 100%;
            }
        }

        &:nth-child(even) {
            flex-direction: row-reverse;

            & .visual {
                width: calc(50% - 2rem);
                padding-right: 2rem;
            }

            & .description {
                width: calc(50% - 2rem);
                padding-left: 2rem;
            }
        }

        &:nth-child(odd) {
            & .visual {
                width: calc(50% - 2rem);
                padding-left: 2rem;
            }

            & .description {
                width: calc(50% - 2rem);
                padding-right: 2rem;
            }
        }
    }

    &:after {
        content: '';
        height: 100%;
        width: 4px;
        bottom: 0;
        left: 50%;
        background: $dark;
        position: absolute;
        background: radial-gradient(at center, darken($accent, 35%), transparent 70%);
    }

    @media screen and (max-width: 600px) {
        align-items: center;
        justify-content: space-between;
        position: unset;
        padding: 1rem;
        gap: 2rem;

        & .project {
            flex-direction: column;
            justify-content: space-between;
            height: fit-content;

            .visual {
                margin-top: 1rem;

                img {
                    width: 100%;
                }
            }

            .description {
                a {
                    margin-top: 0;
                    padding: .2rem 0;
                }
            }

            &:nth-child(even) {
                flex-direction: column;

                & .visual {
                    width: 100%;
                    padding-right: 0;
                }

                & .description {
                    width: 100%;
                    padding-left: 0;
                }
            }

            &:nth-child(odd) {
                & .visual {
                    width: 100%;
                    padding-left: 0;
                }

                & .description {
                    width: 100%;
                    padding-right: 0;
                }
            }
        }

        &:after {
            display: none;
        }
    }
}
</style>