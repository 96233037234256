<script setup>

</script>

<template>
    <section id="selling-point">
        <div class="content">
            <h2>Make sure your business has 24/7 online visibility</h2>
            <p>Boost your business with a platform that increases visibility, encourages customer interaction, and drive growth with a dynamic, cost-effective platform
                designed to build trust, enhance brand identity, and provide valuable insights for your business</p>
        </div>
        <img src="@/assets/tech-tree.webp" alt="Tech tree image">
    </section>
</template>

<style scoped lang="scss">
@import "/src/assets/styles/app.scss";

#selling-point {
    margin: 10rem 0;
    text-align: left;
    display: flex;
    gap: 2rem;
    background: $accent;
    padding-top: 2rem;
    padding-bottom: 3rem;

    img {
        width: 50%;
    }

    .content {
        width: 50%;

        h2 {
            font-size: 3rem;
        }

        p {
            font-size: 1.2rem;
            font-weight: 400;
            line-height: 2rem;
            color: $dark;
            margin: 3rem 0;
        }
    }

    @media screen and (max-width: 600px) {
        margin: 0;
        flex-direction: column;
        gap: 0;
        padding-right: 1rem;
        padding-left: 1rem;

        img {
            width: 100%;
        }

        .content {
            width: 100%;

            h2 {
                font-size: 2rem;
            }

            p {
                margin: 0 0 1rem 0;
                font-size: .9rem;
                line-height: 1.4rem;
            }
        }
    }
}
</style>