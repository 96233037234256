<script setup>

</script>

<template>
    <div id="footer">
        <div class="socials">
            <div class="logo">
                <img src="@/assets/flame.svg" alt="Sead Silajdzic web developer logo">
                <h3>Sead Silajdzic</h3>
            </div>
            <div class="links">
                <a href="mailto:sead.dev.98@gmail.com" target="_blank">
                    <img src="@/assets/email.svg" alt="Sead Silajdzic email"> Email
                </a>
                <a href="https://www.linkedin.com/in/sead-silajdzic/" target="_blank">
                    <img src="@/assets/linkedin.svg" alt="Sead Silajdzic linkedin profile"> LinkedIn
                </a>
                <a href="https://github.com/SeadSilajdzic" target="_blank">
                    <img src="@/assets/github.png" alt="Sead Silajdzic github profile"> Github
                </a>
            </div>
            <div class="credits">
                &copy; 2024 Sead Silajdzic. All rights reserved.
            </div>
        </div>
        <div class="navigation">
            <h4>Navigation</h4>
            <RouterLink :to="{name: 'home', hash: '#hero'}">Home</RouterLink>
            <RouterLink :to="{name: 'about'}">About</RouterLink>
            <RouterLink :to="{name: 'home', hash: '#services'}">Services</RouterLink>
            <RouterLink :to="{name: 'home', hash: '#projects'}">Work</RouterLink>
            <RouterLink :to="{name: 'home', hash: '#contact'}">Contact</RouterLink>
        </div>
        <div class="contact">
            <h4>Get in touch</h4>
            <a href="#">Support</a>
            <a href="#">Reach out</a>
        </div>
        <div class="legal">
            <h4>Legal</h4>
            <a href="#">Privacy policy</a>
            <a href="#">Terms of service</a>
        </div>
    </div>
</template>

<style scoped lang="scss">
@import "/src/assets/styles/app.scss";

#footer {
    max-width: 1250px;
    margin-left: auto !important;
    margin-right: auto !important;
    display: flex;
    align-items: start;
    justify-content: space-between;
    margin-bottom: 5rem;

    img {
        height: 50px;
    }

    h4 {
        font-size: 1rem;
    }

    & .socials {
        display: flex;
        flex-direction: column;
        flex: 3;
        align-items: start;
        gap: 3rem;

        & .logo {
            display: flex;
            align-items: center;
            gap: 1rem;

            h3 {
                font-size: 2rem;
                margin: 0;
            }
        }

        & .links {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            font-size: 1rem;

            a {
                text-decoration: none;
                color: $dark;
                display: flex;
                align-items: center;
                gap: 1rem;
                transition: color .4s;

                &:hover {
                    color: $dark;
                }

                img {
                    height: 20px;
                    filter: brightness(0%);
                }
            }
        }

        & .credits {
            display: flex;
            font-size: 1rem;
            color: $dark;
        }
    }

    & .navigation,
    & .contact,
    & .legal {
        display: flex;
        flex-direction: column;
        flex: 1;
        gap: 1rem;

        a {
            font-size: .9rem;
            color: $dark;
            text-decoration: none;
            transition: color .4s;

            &:hover {
                color: $dark;
            }
        }
    }

    @media screen and (max-width: 600px) {
        flex-direction: column;
        padding: 0 1rem 4rem 1rem;
        gap: 1.5rem;
        position: relative;

        & .socials {
            & .credits {
                position: absolute;
                bottom: 0;
            }
        }
    }
}
</style>