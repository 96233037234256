import {createRouter, createWebHistory} from 'vue-router'
import Home from "@/views/Home.vue";
import Services from "@/views/Services.vue";
import About from "@/views/About.vue";

const routes = [
    {
        path: '/',
        name: 'home',
        component: Home
    },
    {
        path: '/about',
        name: 'about',
        component: About
    },
    {
        path: '/services',
        name: 'services',
        component: Services
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (to.hash) {
            return {
                el: to.hash,
                behavior: "smooth",
                top: 100,
            };
        }
        if (savedPosition) {
            return savedPosition;
        }
        return {top: 0};
    },
})

export default router
