<script setup>

</script>

<template>
    <section id="about">
        <h3>Hey there</h3>
        <div class="about">
            <div class="content">
                <p>
                    My name is Sead and I am a 26-year-old web developer from Bosnia and Herzegovina.
                </p>
                <p>
                    I began my career as a freelance web developer, which over the years led me more and more to backend development. I have worked on various projects, from basic blogs, webshops, many restaurant/hotel systems, RESTful API applications, and SaaS projects. Each role taught me the importance of scalable and efficient code, as well as the value of clear documentation and maintenance.
                </p>
                <p>
                    After almost 5 years of professional development, my passion for PHP/Laravel remained. Meanwhile, I have expanded my knowledge of MySql and JS/Vue.JS. One of my notable projects involved the integration of various Google services to the Laravel app which was then used to provide data to 90+ WP sites via the plugin I have developed. I also developed a Slack bot for customized notifications and team communications, showcasing my ability to innovate and solve complex problems.
                </p>
                <p>
                    Some of the services I am offering are:
                    <ul>
                        <li>Backend development - Development of robust and scalable server-side applications</li>
                        <li>Frontend development - Implementation of intuitive user interfaces</li>
                        <li>Database optimization and API development</li>
                    </ul>
                </p>

                <p>
                    Looking ahead, I am excited to explore new challenges in the field of web development. Even though I am limited by time, it does not stop me from continuous self-development. Currently, I have a list of topics in which I am interested and working on expanding my knowledge. Some of them are microservices and asynchronous applications, CI/CD, PostgreSQL and NoSQL databases, Software Architecture and Design, TDD and DDD, Docker and Kubernetes, and many more...
                </p>
                <p>
                    If you have a project you would like to work on together or if you need consulting, feel free to
                    contact me
                    via
                    email at <span class="email"><a href="mailto:sead.dev.98@gmail.com">sead.dev.98@gmail.com</a></span>
                    or you can use the contact form on the website
                    <span class="site"><a href="silajdzicsead.com" target="_blank">silajdzicsead.com</a></span>.
                </p>
            </div>
        </div>
    </section>
</template>

<style scoped lang="scss">
@import "/src/assets/styles/app.scss";

#about {
    margin-bottom: 4rem;

    h3 {
        font-size: 2rem;
    }

    .about {
        display: flex;

        .content {
            padding: 1rem 1rem 1rem 0;
            line-height: 1.5rem;
            display: flex;
            flex-direction: column;

            p {
                font-size: 1rem;
            }

            ul {
                list-style: upper-roman;

                li {
                    font-size: 1rem;
                }
            }

            span {
                position: relative;
                transition: all .4s;

                a {
                    color: $dark;
                    transition: all .4s;
                }

                &:after {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 0;
                    background: $accent;
                    transition: all .4s;
                    pointer-events: none;
                }

                &:hover {
                    padding: 0 .2rem;

                    a {
                        z-index: 4;
                        color: $dark;
                    }

                    &:after {
                        height: 100%;
                        border: 1px solid $accent;
                        z-index: -1;
                        background: $accent;
                    }
                }
            }
        }
    }

    @media screen and (max-width: $mobileMedia) {
        #about {
            padding: 0 1rem; // in app.scss

            h3 {
                padding: unset;
                margin-bottom: 0;
            }

            .about {
                display: flex;
                flex-direction: column-reverse;
            }
        }
    }
}
</style>